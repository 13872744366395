body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


option:hover {
  background-color: rgb(6, 184, 149); /* poner el color que gustes */
}


.tooltip-inner {
  background-color: #009966;
  }
  .tooltip.bs-tooltip-right .arrow:before {
      border-right-color: #009966 !important;
  }
  .tooltip.bs-tooltip-left .arrow:before {
      border-left-color: #009966 !important;
  }
  .tooltip.bs-tooltip-bottom .arrow:before {
      border-bottom-color: #009966 !important;
  }
  .tooltip.bs-tooltip-top .arrow:before {
      border-top-color: #009966 !important;
  }

  body{margin-top:20px;}

.margin30 {
    margin-bottom: 30px;
}

.item-img-wrap {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.item-img-wrap img {
    -moz-transition: all 200ms linear;
    -o-transition: all 200ms linear;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    width: 100%;
}

/************************image hover effect*******************/
.item-img-wrap {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.item-img-wrap img {
  -moz-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 100%;
}

.item-img-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.item-img-overlay span {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(http://bootstraplovers.com/templates/assan-2.2/main-template/img/plus.png) no-repeat center center rgba(0, 0, 0, 0.7);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -moz-transition: opacity 250ms linear;
  -o-transition: opacity 250ms linear;
  -webkit-transition: opacity 250ms linear;
  transition: opacity 250ms linear;
}

.item-img-wrap:hover .item-img-overlay span {
  opacity: 1;
}

.item-img-wrap:hover img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


/*************pagination***********/
.gallery-bottom .pagination {
  margin-top: 0px;
}

.pagination > li > a, .pagination > li > span {
  background-color: #ccc;
  padding: 3px 9px;
  color: #fff;
  border: 0px;
}

.pagination > li > a {
  margin-right: 5px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: #32c5d2;
}